define("hubba-front-v3/_pods/components/table/cell/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCTpHIod",
    "block": "[[[41,[30,1],[[[1,\"  \"],[1,[28,[35,1],[[30,1],[28,[37,2],[[30,2,[\"format\"]],\"MMM DD, YYYY\"],null]],null]],[1,\"\\n\"]],[]],[[[1,\"  -\\n\"]],[]]]],[\"@rawValue\",\"@column\"],false,[\"if\",\"moment-format\",\"or\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/date/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});