define("hubba-front-v3/_pods/components/table/cell/event-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kg+DqKsy",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"flex flex-wrap pl-2 pr-4 border-r border-titlebox-blue font-semibold text-sm\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[28,[37,2],[[30,2,[\"content\",\"currentUserAttendeeStatus\"]],\"attended\"],null]],null],[[[1,\"      \"],[10,0],[14,0,\"absolute h-6 w-6 rounded-full flex items-center justify-center text-center text-xxxs font-bold\"],[14,5,\"margin-left: 3.25rem; opacity: 50%\"],[12],[1,[28,[35,3],[\"checkmark\"],[[\"class\"],[\"mr-2\"]]]],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[28,[37,4],[[28,[37,2],[[30,2,[\"content\",\"currentUserAttendeeStatus\"]],\"attended\"],null]],null],[28,[37,5],[[30,2,[\"content\",\"startDate\"]],[33,6]],null]],null],[[[1,\"      \"],[10,0],[14,0,\"absolute h-6 w-6 rounded-full flex items-center justify-center text-center text-xxxs font-bold\"],[14,5,\"margin-left: 3.25rem; opacity: 50%\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[28,[37,2],[[30,2,[\"content\",\"currentUserAttendeeStatus\"]],\"invited\"],null],[28,[37,7],[[30,2,[\"content\",\"startDate\"]],[33,6]],null]],null],[[[1,\"      \"],[10,0],[14,0,\"absolute h-4 w-4 rounded-full bg-orange-400 flex items-center justify-center text-center text-xxxs font-bold\"],[14,5,\"margin-left: 3.25rem; opacity: 50%\"],[12],[1,[28,[35,3],[\"envelope\"],[[\"class\"],[\"w-3\"]]]],[13],[1,\"\\n    \"]],[]],null]],[]]]],[]]],[1,\"\\n    \"],[1,[28,[35,8],[[30,1],[28,[37,9],[[30,3,[\"format\"]],\"MM/DD\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  -\\n\"]],[]]]],[\"@rawValue\",\"@row\",\"@column\"],false,[\"if\",\"and\",\"eq\",\"svg-jar\",\"not\",\"is-before\",\"today\",\"is-after\",\"moment-format\",\"or\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/event-date/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});