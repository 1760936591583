define("hubba-front-v3/_pods/components/table/cell/text-overflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BniYIOJ8",
    "block": "[[[10,0],[14,0,\"truncate\"],[12],[1,\"\\n\"],[41,[30,1,[\"tooltip\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@text\",\"@side\"],[[30,2],[52,[30,1,[\"tooltipSide\"]],[30,1,[\"tooltipSide\"]],\"left\"]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@column\",\"@rawValue\"],false,[\"if\",\"ember-tooltip\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/text-overflow/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});