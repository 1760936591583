define("hubba-front-v3/_pods/components/table/cell/requestor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kIzjSGzp",
    "block": "[[[41,[30,1,[\"content\",\"user\",\"email\"]],[[[1,\"  \"],[10,3],[15,6,[29,[\"mailto:\",[30,1,[\"content\",\"user\",\"email\"]]]]],[12],[1,[30,1,[\"content\",\"user\",\"formalName\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"content\",\"user\",\"id\"]],[[[1,\"    \"],[1,[30,1,[\"content\",\"user\",\"formalName\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"content\",\"company\",\"shortName\"]]],[1,\"\\n\"]],[]]]],[]]]],[\"@row\"],false,[\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/requestor/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});