define("hubba-front-v3/_pods/components/table/cell/event-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SSDs5ioT",
    "block": "[[[10,0],[14,0,\"flex w-full\"],[15,\"onclick\",[28,[37,0],[\"events-index-table-row-link\"],null]],[12],[1,\"\\n\"],[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"flex-1 ml-2 font-semibold text-sm hover:underline line-clamp-2 self-center\",\"events.view\",[30,1,[\"content\",\"slug\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[30,2]],[1,\"\\n    \"],[8,[39,2],null,[[\"@text\"],[[30,2]]],null],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"ml-2 line-clamp-1 text-gray-500 text-xxs\"],[12],[1,\"\\n    \"],[1,[34,3]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@row\",\"@rawValue\"],false,[\"set-referrer-link-id\",\"link-to\",\"ember-tooltip\",\"topicTags\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/event-name/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});