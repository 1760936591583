define("hubba-front-v3/_pods/components/table/cell/forum-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhezAUCA",
    "block": "[[[41,[30,1,[\"forum\",\"name\"]],[[[1,\"  \"],[6,[39,1],null,[[\"route\",\"model\"],[\"forum.agenda\",[30,1,[\"forum\",\"slug\"]]]],[[\"default\"],[[[[1,[30,1,[\"forum\",\"name\"]]]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@row\"],false,[\"if\",\"link-to\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/forum-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});