define("hubba-front-v3/_pods/components/table/base-events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mu+yElon",
    "block": "[[[6,[39,0],[[33,1]],[[\"height\",\"extra\",\"width\",\"responsive\",\"class\"],[[52,[30,1],[30,1],\"100%\"],[28,[37,3],null,[[\"tableName\"],[[33,4]]]],\"100%\",true,[28,[37,5],[\"table table-hover table-sm \",[33,6]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[30,2,[\"head\"]],null,[[\"fixed\",\"class\",\"resizeOnDrag\",\"onColumnClick\",\"iconComponent\",\"iconSortable\",\"iconAscending\",\"iconDescending\"],[true,\"text-bold events-table-header text-center mb-4\",true,[28,[37,7],[[30,0],\"onColumnClick\"],null],\"table/icon\",\"sort\",\"sort-up\",\"sort-down\"]]]],[1,\"\\n\"],[6,[30,2,[\"body\"]],null,[[\"canSelect\",\"selectOnClick\",\"onRowClick\",\"rowComponent\"],[false,false,[28,[37,7],[[30,0],\"onRowClick\"],null],[33,8]]],[[\"default\"],[[[[41,[33,9],[[[1,\"      \"],[10,0],[14,0,\"lds-ring centered\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,10],[[28,[37,11],[[33,12]],null],[33,1,[\"isEmpty\"]],[30,4]],null],[[[6,[30,3,[\"no-data\"]],null,null,[[\"default\"],[[[[1,\"        \"],[10,\"h3\"],[14,0,\"text-center\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"]],[]],null]],[]]]],[3]]]]]],[2]]]]]],[\"@height\",\"t\",\"body\",\"@emptyTableText\"],false,[\"light-table\",\"table\",\"if\",\"hash\",\"tableName\",\"concat\",\"class\",\"action\",\"rowComponent\",\"isLoading\",\"and\",\"not\",\"loading\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/base-events/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});