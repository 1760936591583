define("hubba-front-v3/mirage/factories/car-listing", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _faker.default.lorem.words();
    },
    price: function price() {
      return _faker.default.datatype.number({
        min: 1000,
        max: 50000
      });
    },
    year: function year() {
      return _faker.default.datatype.number({
        min: 1945,
        max: 2021
      });
    },
    miles: function miles() {
      return _faker.default.datatype.number({
        min: 100,
        max: 200000
      });
    },
    make: function make() {
      // return faker.vehicle.manufacturer();
      return "BMW";
    },
    model: function model() {
      return _faker.default.vehicle.model();
    },
    vinNumber: function vinNumber() {
      return _faker.default.datatype.uuid();
    },
    createdAt: function createdAt() {
      return _faker.default.date.past();
    }
  });

  _exports.default = _default;
});