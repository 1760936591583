define("hubba-front-v3/_pods/components/table/cell/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqFRCWOJ",
    "block": "[[[41,[28,[37,1],[[33,2],\"past\"],null],[[[1,\"  \"],[10,0],[14,0,\"flex justify-around px-2\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[6,[39,3],null,[[\"route\",\"model\"],[\"events.view.content\",[33,4,[\"content\",\"slug\"]]]],[[\"default\"],[[[[1,\"        \"],[10,\"img\"],[15,\"src\",[29,[[28,[37,5],[\"images/table-document.svg\"],null]]]],[14,0,\"flex-none cursor-pointer h-12\"],[12],[13],[1,\"\\n        \"],[8,[39,6],null,[[\"@side\",\"@text\"],[\"left\",\"View Presentation\"]],null],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n\"],[6,[39,3],null,[[\"route\",\"model\"],[\"research.view\",[33,4,[\"content\",\"firstEventRecordingContentSlug\"]]]],[[\"default\"],[[[[1,\"        \"],[10,\"img\"],[15,\"src\",[29,[[28,[37,5],[\"images/table-play.svg\"],null]]]],[14,0,\"flex-none cursor-pointer h-12\"],[12],[13],[1,\"\\n        \"],[8,[39,6],null,[[\"@side\",\"@text\"],[\"left\",\"Watch Recording\"]],null],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,1],[[33,2],\"live\"],null],[[[41,[28,[37,7],[[33,4,[\"content\",\"isJoinable\"]],[33,4,[\"content\",\"isAccessible\"]]],null],[[[1,\"    \"],[1,[28,[35,8],null,[[\"forum\",\"analyticsEventMetaTarget\",\"isDisabled\",\"smallButton\"],[[33,4,[\"content\"]],\"event-table\",false,true]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"\\n\"],[1,\"  \"],[10,0],[14,0,\"text-center text-sm italic\"],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"eq\",\"contentState\",\"link-to\",\"row\",\"img-src\",\"ember-tooltip\",\"and\",\"join-button\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});