define("hubba-front-v3/_pods/components/table/cell/markdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DAtdqRl6",
    "block": "[[[10,0],[14,0,\"flex-grow\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1]],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@rawValue\"],false,[\"markdown-to-html\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/markdown/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});