define("hubba-front-v3/_pods/components/table/cell/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Je285h3",
    "block": "[[[10,0],[14,0,\"toggle-wrapper\"],[15,\"onclick\",[28,[37,0],[[30,1,[\"onToggle\"]],[30,2,[\"content\"]],[28,[37,1],[[30,3]],null]],null]],[12],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"theme\",\"disabled\",\"value\"],[\"ios\",[30,2,[\"content\",\"isSaving\"]],[30,3]]]]],[1,\"\\n\"],[13]],[\"@column\",\"@row\",\"@rawValue\"],false,[\"fn\",\"not\",\"x-toggle\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/toggle/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});