define("hubba-front-v3/_pods/components/table/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Dezh7OQ",
    "block": "[[[8,[39,0],[[24,0,\"lt-sort-icon\"]],[[\"@icon\"],[[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[],false,[\"fa-icon\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});