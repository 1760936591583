define("hubba-front-v3/_pods/components/table/cell/price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzbOgJjO",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,1],[14,0,\"text-green-500\"],[12],[1,\"$\"],[1,[30,1]],[13],[1,\"\\n\"]],[]],null]],[\"@rawValue\"],false,[\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/price/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});