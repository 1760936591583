define("hubba-front-v3/_pods/main/buyer/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/b9ZPSC",
    "block": "[[[1,[28,[35,0],[\"Cars\"],null]],[1,\"\\n\\n\\n\"],[10,0],[14,0,\"bg-white shadow overflow-hidden sm:rounded-md table-container\"],[12],[1,\"\\n\"],[1,\"\\n  \"],[8,[39,1],null,[[\"@dir\",\"@sort\",\"@height\",\"@model\",\"@columns\",\"@sortChange\",\"@onRowClick\",\"@isLoading\"],[[30,0,[\"dir\"]],[30,0,[\"sort\"]],\"100%\",[30,0,[\"carListings\"]],[30,0,[\"columns\"]],[30,0,[\"sortChange\"]],[30,0,[\"onRowClick\"]],[30,0,[\"fetchCarListings\",\"isRunning\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"table/base\"]]",
    "moduleName": "hubba-front-v3/_pods/main/buyer/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});