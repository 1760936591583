define("hubba-front-v3/_pods/components/table/cell/action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxrUv0up",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"actions\"]]],null]],null],null,[[[1,\"  \"],[10,\"button\"],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,2],[[30,0],[30,2,[\"action\"]]],null],[30,3,[\"content\"]]],null]],[14,0,\"btn badge btn-sm btn-secondary\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[2]],null]],[\"@column\",\"item\",\"@row\"],false,[\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/action/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});