define("hubba-front-v3/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.urlPrefix = 'http://localhost:3000';
    this.namespace = '/api';
    this.get('/users');
    this.post('/users');
    this.get('/users/:id');
    this.patch('/users/:id'); // this.get('/car-listings');

    this.post('/car-listings');
    this.get('/car-listings/:id');
    this.patch('/car-listings/:id');
    this.get('/car-listings', function (_ref, _ref2) {
      var carListings = _ref.carListings;
      var queryParams = _ref2.queryParams;

      if (Object.keys(queryParams).length) {
        return carListings.where(queryParams);
      } else {
        return carListings.all();
      }
    });
    this.passthrough('https://upload.filestackapi.com/*');
  }
});