define("hubba-front-v3/_pods/components/table/cell/text-capitalize/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pQVANUqE",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"capitalize\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[12],[1,\"---\"],[13],[1,\"\\n\"]],[]]]],[\"@rawValue\"],false,[\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/text-capitalize/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});