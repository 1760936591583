define("hubba-front-v3/_pods/components/img-slider/component", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['swiper'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.swiper = new _swiper.default(this.element, {
        // loop: true,
        observer: true,
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
          nextEl: '.right-nav',
          prevEl: '.left-nav'
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.swiper.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      slideLeft: function slideLeft() {
        this.swiper.slidePrev();
      },
      slideRight: function slideRight() {
        this.swiper.slideNext();
      },
      viewImage: function viewImage(item) {
        this.set('currentSlide', item);
      },
      closeViewModal: function closeViewModal() {
        this.set('currentSlide', null);
      }
    }
  });

  _exports.default = _default;
});