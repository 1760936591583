define("hubba-front-v3/_pods/components/table/cell/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FXznmpsW",
    "block": "[[[10,0],[14,0,\"table-checkbox\"],[12],[1,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[30,1,[\"content\",\"isNew\"]]],[15,\"checked\",[30,1,[\"selected\"]]],[15,\"onclick\",[28,[37,0],[[30,0],[30,2,[\"selectRow\"]],[30,1]],null]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"table-checkbox__checkmark\"],[12],[13],[1,\"\\n\"],[13]],[\"@row\",\"@tableActions\"],false,[\"action\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/checkbox/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});