define("hubba-front-v3/_pods/components/table/filtered/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    filterValue: '',
    enableFilter: false,
    filterField: Ember.computed('columns', function () {
      return this.columns.filter(function (column) {
        return column.filterable === true;
      })[0].valuePath;
    }),
    filterFieldNormalized: Ember.computed('columns', 'filterField', function () {
      var filterField = this.filterField;
      return this.columns.find(function (_ref) {
        var valuePath = _ref.valuePath;
        return valuePath === filterField;
      }).label;
    }),
    filteredModels: Ember.computed('model.length', 'filterValue', 'filterField', 'sort', 'dir', function () {
      var filterVal = this.filterValue.trim();
      var filtered;
      var model = this.model || [];

      if (filterVal === '') {
        filtered = model.toArray();
      } else {
        var regexp = new RegExp(filterVal, 'gi');
        var filterField = this.filterField;
        filtered = model.filter(function (val) {
          var fieldVal = Ember.get(val, filterField); // special case: filter by nested forum relationship

          if (filterField === 'forums') {
            return val.get('forums').toArray().mapBy('fullName').some(function (forumName) {
              return regexp.test(forumName);
            });
          } else {
            return regexp.test(fieldVal);
          }
        });
      }

      var sortBy = this.sort;
      var isReverse = this.dir === 'desc' ? -1 : 1;
      filtered = filtered.sort(function (a, b) {
        return isReverse * (a[sortBy] > b[sortBy] ? 1 : -1);
      });
      return filtered;
    }),
    actions: {
      setFilteredValue: function setFilteredValue(val, forceSet) {
        if (forceSet === true) {
          this.set('filterValue', val);
        } else {
          Ember.run.debounce(Ember.set, this, 'filterValue', val, 400);
        }
      }
    }
  });

  _exports.default = _default;
});