define("hubba-front-v3/_pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMyl4mxf",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"container-toasts\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],[[24,0,\"flash-message--toast\"]],[[\"@flash\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"message\"]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[1]],null],[13]],[\"flash\",\"component\",\"flash\"],false,[\"component\",\"-outlet\",\"each\",\"-track-array\",\"flash-message\"]]",
    "moduleName": "hubba-front-v3/_pods/application/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});