define("hubba-front-v3/_pods/components/table/cell/mailto/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1g2MinzZ",
    "block": "[[[41,[30,1,[\"user\",\"email\"]],[[[1,\"  \"],[10,3],[15,6,[29,[\"mailto:\",[30,1,[\"user\",\"email\"]]]]],[12],[1,[30,1,[\"user\",\"formalName\"]]],[13],[1,\"\\n\"]],[]],null]],[\"@row\"],false,[\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/mailto/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});