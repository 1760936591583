define("hubba-front-v3/_pods/components/table/cell/text-with-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "81PzUPLz",
    "block": "[[[10,0],[14,0,\"flex-grow truncate\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[33,1],\"-\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[28,[37,3],[[33,4],[28,[37,5],[[33,6,[\"classification\"]],\"darcy\"],null]],null],[[[1,\"  \"],[10,3],[15,6,[36,4]],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[1,[28,[35,7],[\"external-link\"],[[\"class\"],[\"float-right text-blue-700\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"or\",\"value\",\"if\",\"and\",\"url\",\"eq\",\"currentUser\",\"fa-icon\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/text-with-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});