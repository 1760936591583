define("hubba-front-v3/_pods/components/table/icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    icon: Ember.computed('sortIcons', 'sortIconProperty', function () {
      return Ember.get(this.sortIcons, this.sortIconProperty);
    })
  });

  _exports.default = _default;
});