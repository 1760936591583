define("hubba-front-v3/_pods/components/image-view/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    imageLoaded: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.imageUrl) {
        var image = new Image();
        image.src = this.imageUrl;
        image.onload = this.onImageLoad.bind(this);
      }

      this.lockScroll();
    },
    willDestroyElement: function willDestroyElement() {
      this.unlockScroll();
    },
    isLoading: Ember.computed('imageLoaded', function () {
      return !this.imageLoaded;
    }),
    lockScroll: function lockScroll() {
      (0, _jquery.default)('html').addClass('lock-scroll');
    },
    unlockScroll: function unlockScroll() {
      (0, _jquery.default)('html').removeClass('lock-scroll');
    },
    onImageLoad: function onImageLoad() {
      if (this.get('isDestroyed')) return;
      this.set('imageLoaded', true);
    }
  });

  _exports.default = _default;
});