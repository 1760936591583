define("hubba-front-v3/_pods/components/table/cell/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXDTDLFa",
    "block": "[[[10,0],[15,0,[29,[\"status-cell\",[52,[33,1],\" has-label\"],[52,[28,[37,2],[[33,1,[\"length\"]],2],null],\" has-second-label\"],[52,[28,[37,3],[[33,1,[\"length\"]],2],null],\" has-several-labels\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"showValue\"]],[[[1,\"    \"],[6,[39,4],null,null,[[\"default\"],[[[[1,[30,2]]],[]]]]],[1,\"\\n\"]],[]],null],[41,[33,1],[[[41,[28,[37,5],[[33,1]],null],[[[42,[28,[37,7],[[28,[37,7],[[33,1]],null]],null],null,[[[1,\"        \"],[10,0],[15,0,[52,[30,1,[\"showValue\"]],\"float-right\"]],[12],[1,\"\\n          \"],[10,1],[15,0,[29,[\"badge badge-pill \",[52,[30,1,[\"showValue\"]],\"mx-1\"],\" \",[28,[37,8],[[30,1,[\"styles\"]],[28,[37,9],[[30,3],\"\"],null]],null]]]],[12],[1,[30,3]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null]],[]],[[[1,\"      \"],[10,0],[15,0,[52,[30,1,[\"showValue\"]],\"float-right\"]],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[\"badge badge-pill \",[52,[30,1,[\"showValue\"]],\"mx-1\"],\" \",[28,[37,8],[[30,1,[\"styles\"]],[28,[37,9],[[33,1],\"\"],null]],null]]]],[12],[1,[34,1]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@column\",\"@rawValue\",\"status\"],false,[\"if\",\"status\",\"eq\",\"gt\",\"text-overflow\",\"is-array\",\"each\",\"-track-array\",\"get\",\"concat\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/status/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});