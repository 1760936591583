define("hubba-front-v3/_pods/components/table/cell/events-topic-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPcy5UzW",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"line-clamp-2\"],[12],[1,\"\\n     \"],[1,[34,1]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@rawValue\"],false,[\"if\",\"topicTags\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/events-topic-tags/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});