define("hubba-front-v3/_pods/components/table/column/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XL3O3J3o",
    "block": "[[[10,0],[14,0,\"table-checkbox\"],[12],[1,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[28,[37,0],[[30,0,[\"filteredRows\"]]],null]],[15,\"checked\",[30,1,[\"isAllSelected\"]]],[15,\"onchange\",[30,2,[\"toggleAll\"]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"table-checkbox__checkmark\"],[12],[13],[1,\"\\n\"],[13]],[\"@extra\",\"@tableActions\"],false,[\"not\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/column/checkbox/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});