define("hubba-front-v3/_pods/components/table/base/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D2CxDuKN",
    "block": "[[[6,[39,0],[[33,1]],[[\"height\",\"width\",\"responsive\",\"class\"],[[52,[30,1],[30,1],\"100%\"],\"100%\",true,[28,[37,3],[\"table-list \",[33,4]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[30,2,[\"head\"]],null,[[\"fixed\",\"onColumnClick\",\"iconComponent\",\"iconSortable\",\"iconAscending\",\"iconDescending\"],[true,[28,[37,5],[[30,0],\"onColumnClick\"],null],\"table/icon\",\"sort\",\"sort-up\",\"sort-down\"]]]],[1,\"\\n\"],[6,[30,2,[\"body\"]],null,[[\"canSelect\",\"onRowClick\"],[false,[30,3]]],[[\"default\"],[[[[41,[33,6],[[[1,\"      \"],[10,0],[14,0,\"lds-ring centered\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[4]]]]]],[2]]]]]],[\"@height\",\"t\",\"@onRowClick\",\"body\"],false,[\"light-table\",\"table\",\"if\",\"concat\",\"class\",\"action\",\"isLoading\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/base/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});