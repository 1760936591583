define("hubba-front-v3/_pods/components/table/cell/invite-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "weTvaWu6",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[30,1],[[[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[]],[[[1,\"    <held seat>\\n\"]],[]]]],[]]]]]],[\"@rawValue\"],false,[\"text-overflow\",\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/invite-name/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});