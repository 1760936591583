define("hubba-front-v3/_pods/components/table/cell/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contentState: Ember.computed('row.content.saneStartDate', 'row.content.saneEndDate', 'row.content.isJoinable', function () {
      var now = moment();
      var startDate = this.get('row.content.saneStartDate');
      var endDate = this.get('row.content.saneEndDate'); // if within the last week

      if (endDate.isBefore(now)) {
        return 'past'; //today
      } else if (startDate.isSame(now, 'day')) {
        return 'live'; //tomorrow or later
      } else if (startDate.isAfter(now)) {
        return 'Upcoming';
      }
    })
  });

  _exports.default = _default;
});