define("hubba-front-v3/_pods/components/table/cell/events-topic-tags/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    topicTags: Ember.computed('row.content', 'topicTagList', function () {
      var topicTagList = this.get('row.content.topicTags').map(function (topicTag) {
        return topicTag.name;
      });

      if (topicTagList.includes("Executive")) {
        topicTagList = "Executive";
      } else {
        topicTagList = topicTagList.join(', ');
      }

      return topicTagList;
    })
  });

  _exports.default = _default;
});