define("hubba-front-v3/_pods/components/table/cell/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q1tpM5R7",
    "block": "[[[41,[30,1,[\"content\",\"images\",\"firstObject\",\"url\"]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center -my-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-shrink-0 w-16 h-16 rounded-md\"],[15,5,[29,[\"background-image: url('\",[30,1,[\"content\",\"images\",\"firstObject\",\"url\"]],\"'); background-size: cover; background-position: center; background-repeat: no-repeat;\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center -my-3\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"car-icon\"]],[[\"@icon\"],[\"car\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@row\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/image/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});