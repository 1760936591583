define("hubba-front-v3/_pods/components/table/row/events/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({});

  _exports.default = _default;
});