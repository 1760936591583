define("hubba-front-v3/_pods/components/image-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8poCPfB",
    "block": "[[[6,[39,0],null,[[\"translucentOverlay\",\"containerClass\"],[true,\"viewModal bg-white rounded shadow-lg z-50 overflow-y-auto\"]],[[\"default\"],[[[[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"loading-icon\"]],[[\"@icon\",\"@size\",\"@spin\"],[\"spinner\",\"2x\",true]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,\"onclick\",[36,3]],[14,0,\"absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-5 text-white text-sm z-30\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\",\"@size\"],[\"times\",\"2x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"img\"],[14,0,\"imageView\"],[15,\"src\",[30,0,[\"imageUrl\"]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"modal-dialog\",\"if\",\"fa-icon\",\"closeModal\"]]",
    "moduleName": "hubba-front-v3/_pods/components/image-view/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});