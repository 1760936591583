define("hubba-front-v3/_pods/components/table/cell/topic-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WJ2vPt/d",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"  \"],[10,\"ul\"],[14,0,\"m-0 pl-0 italic\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@rawValue\",\"tag\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/topic-tags/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});