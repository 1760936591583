define("hubba-front-v3/_pods/components/table/cell/icon-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "21iqfO8f",
    "block": "[[[10,1],[14,0,\"relative inline-block px-3 py-1 font-semibold text-white leading-3\"],[12],[1,\"\\n  \"],[10,1],[14,\"aria-hidden\",\"\"],[15,0,[29,[\"absolute inset-0 opacity-50 rounded-full \",[52,[28,[37,1],[[30,1],\"automatic\"],null],\"bg-green-600\",[52,[28,[37,1],[[30,1],\"semi\"],null],\"bg-blue-500\",\"bg-pink-600\"]]]]],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"relative\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@rawValue\"],false,[\"if\",\"eq\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/cell/icon-text/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});