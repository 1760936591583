define("hubba-front-v3/tailwind/config", [], function () {
  "use strict";

  module.exports = {
    purge: [],
    darkMode: false,
    // or 'media' or 'class'
    theme: {
      extend: {}
    },
    variants: {
      extend: {}
    },
    plugins: [require("@tailwindcss/forms")]
  };
});