define("hubba-front-v3/_pods/main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2qqRashg",
    "block": "[[[1,[28,[35,0],[\"Main\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"flex flex-col h-full\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@currentUser\"],[[30,0,[\"currentUser\",\"user\"]]]],null],[1,\"\\n\\n  \"],[10,\"main\"],[14,0,\"h-full bg-gray-100\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"h-full max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8\"],[12],[1,\"\\n\\n      \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"navbar\",\"component\",\"-outlet\"]]",
    "moduleName": "hubba-front-v3/_pods/main/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});