define("hubba-front-v3/_pods/components/table/row/events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65nBEyso",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[46,[28,[37,4],[\"light-table/cells/\",[30,1,[\"cellType\"]]],null],[[30,1],[33,5]],[[\"table\",\"class\",\"rawValue\",\"tableActions\",\"extra\"],[[33,6],[28,[37,4],[\"events-table-data\",[52,[51,[30,2,[\"content\",\"isAccessible\"]]],\" is-preview-only\"]],null],[28,[37,8],[[33,5],[30,1,[\"valuePath\"]]],null],[33,9],[33,10]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[44,[[30,2,[\"content\"]]],[[],[3]]]],[\"column\",\"@row\",\"request\"],false,[\"each\",\"-track-array\",\"columns\",\"component\",\"concat\",\"row\",\"table\",\"unless\",\"get\",\"tableActions\",\"extra\",\"let\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/row/events/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});