define("hubba-front-v3/_pods/components/img-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GKoRX85I",
    "block": "[[[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"swiper-slide\"],[12],[1,\"\\n      \"],[10,0],[15,\"onclick\",[28,[37,2],[[30,0],\"viewImage\",[30,2]],null]],[14,0,\"swiper-slide-img backgound-cover\"],[15,5,[29,[\"background-image: url('\",[30,2],\"')\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,1,[\"length\"]],1],null],[[[1,\"  \"],[11,0],[24,0,\"left-nav\"],[4,[38,2],[[30,0],\"slideLeft\"],null],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"chevron-left\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"right-nav\"],[4,[38,2],[[30,0],\"slideRight\"],null],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"chevron-right\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[33,6],[[[1,\"  \"],[1,[28,[35,7],null,[[\"imageUrl\",\"closeModal\"],[[33,6],[28,[37,2],[[30,0],\"closeViewModal\"],null]]]]],[1,\"\\n\"]],[]],null]],[\"@items\",\"item\"],false,[\"each\",\"-track-array\",\"action\",\"if\",\"gt\",\"fa-icon\",\"currentSlide\",\"image-view\"]]",
    "moduleName": "hubba-front-v3/_pods/components/img-slider/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});