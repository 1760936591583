define("hubba-front-v3/_pods/components/table/column/with-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "34shu1iL",
    "block": "[[[1,[30,1,[\"label\"]]],[1,\" \"],[10,1],[14,0,\"badge badge-secondary\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[8,[39,1],[[24,0,\"lt-sort-icon\"]],[[\"@icon\"],[[52,[30,1,[\"sorted\"]],[52,[30,1,[\"ascending\"]],[30,2,[\"iconAscending\"]],[30,2,[\"iconDescending\"]]],[30,2,[\"iconSortable\"]]]]],null],[1,\"\\n\"]],[\"@column\",\"@sortIcons\"],false,[\"count\",\"fa-icon\",\"if\"]]",
    "moduleName": "hubba-front-v3/_pods/components/table/column/with-count/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});